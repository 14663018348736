<template>
  <div>
    <a-modal
      v-model="visible"
      :title="$t('编辑单据字头')"
      :confirmLoading="loading"
      :maskClosable="false"
      centered
      @cancel="cancel"
      @ok="confirm"
    >
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="name" :label="$t('订单类型')">
            <a-input :value="form.type_display" disabled />
          </a-form-model-item>
          <a-form-model-item prop="text" :label="$t('字头')">
            <a-input v-model="form.text" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { orderPrefixUpdate } from "@/api/system";
import rules from "./rules.js";

export default {
  name: "FormModal",
  props: ["visible", "form"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules,
      loading: false,
    };
  },
  methods: {
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          orderPrefixUpdate(this.form)
            .then((data) => {
              this.$message.success(this.$t("修改成功"));
              this.$emit("update", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style scoped></style>
